import { styled, css } from 'styled-components';

export const cssMainGrid = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
  max-width: 1024px;
`;

export const Hidden = styled.input.attrs({ type: 'hidden' })``;
